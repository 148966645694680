<template>
  <div>
    <Topbar></Topbar>
    <div class="check-content">
      <div class="check-top">选择收货地址</div>
      <div class="address" v-if="!ziti">
        <div
          :class="[act == index ? ' address-item' : ' address-item2']"
          v-for="(item, index) in address"
          :key="index"
          @click="change(index, address)"
        >
          <div class="address-item-top">
            <div class="name">{{ item.ContactName }} 收</div>
            <div class="default" v-if="item.IsDefault == 1">默认地址</div>
            <div
              class="default"
              v-if="item.IsDefault != 1 && act == index"
              @click="changeaddress(index)"
            >
              设为默认地址
            </div>
          </div>
          <div class="address-item-content">
            <div class="">{{ item.Province + item.City + item.Area }}</div>
            <div>{{ item.Address }}</div>
            <div class="">{{ item.ContactPhone }}</div>
          </div>
        </div>
      </div>
      <div class="address" v-if="ziti">
        <div class="address-item">
          <div class="address-item-content" style="margin-top: 40px">
            <div>{{ zitiaddress }}</div>
          </div>
        </div>
      </div>
      <div class="bigadd">
        <div class="add" @click="tianjia()">+新增收货地址</div>
        <div class="add" @click="guanli()" style="margin-left: 15px">
          +管理收货地址
        </div>
      </div>

      <div class="check-top2">收货方式</div>
      <div class="way">
        <div
          :class="[item.ischeck ? ' package' : ' Self']"
          v-for="(item, index) in way"
          :key="index"
          @click="change2(index, way)"
        >
          {{ item.text }}
        </div>
        <!-- <div class="Self">自提</div> -->
      </div>

      <div class="check-top2">确认商品信息</div>
      <div class="shop-detail">
        <div class="detail-top">
          <div
            class="top-item"
            v-for="(item, index) in detailtoplist"
            :key="index"
          >
            {{ item.text }}
          </div>
        </div>
        <div
          class="goods-item"
          v-for="(item, index) in settlement.GoodsDetailList"
          :key="index"
        >
          <div class="content-left-img">
            <img :src="item.CoverImg" alt="" />
          </div>
          <div class="content-left-font">
            <div class="font1">{{ item.GoodsName }}</div>
            <div class="font2">{{ item.SpecName }}</div>
          </div>
          <div class="content-jifen" v-if="item.Points > 0">
            {{ item.Points / item.Count }}积分+
          </div>
          <div :class="[item.Points > 0 ? 'content-price' : 'content-price2']">
            ￥{{ (item.Price / item.Count).toFixed(2) }}
          </div>
          <div class="content-num">{{ item.Count }}</div>
          <div class="off">
            <span v-if="item.Points > 0">{{ item.Points }}积分+</span>
            <span>￥{{ item.Price }}</span>
          </div>
        </div>
        <div class="liuyanbox">
          <div class="liuyanfont">给商家留言：</div>
          <input
            type="text"
            placeholder="选填最多100个字"
            v-model="beizhu"
            maxlength="100"
          />
          <div class="beizhunum">{{ beizhu.length }}/100</div>
        </div>
      </div>
      <!-- 最下面 -->
      <div class="goods-add">
        <div class="pay">
          <div class="type-select">
            <div
              class="type-item"
              v-for="(item, index) in typelist"
              :key="index"
              @click="change3(index, typelist)"
            >
              <div :class="[item.ischeck ? 'type-left2' : ' type-left']"></div>
              <div class="type-right">
                <img :src="item.img" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="goods-content">
          <div class="youhui"></div>
          <div
            :class="[
              item.important ? 'goods-content-item2' : ' goods-content-item',
            ]"
            v-for="(item, index) in addlist"
            :key="index"
          >
            <div class="goods-left">{{ item.left }}</div>
            <div class="goods-right">{{ item.right }}</div>
          </div>
        </div>
      </div>
      <div class="queren" @click="payshop()">提交订单</div>
    </div>
    <!-- 新增地址 -->
    <el-dialog
      title="新增地址"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <!-- <el-form-item label="所在地区" prop=""> -->
      <div class="dialong-item">
        <div class="item-left">所在地区</div>
        <el-cascader
          size="large"
          :options="options"
          v-model="selectedOptions"
          @change="handleChangeAdrs"
        >
        </el-cascader>
      </div>

      <!-- </el-form-item> -->
      <div class="dialong-item">
        <div class="item-left">详细地址</div>
        <el-input
          v-model="ruleForm.adress"
          placeholder="无需重复填写省市区，小于75个字"
        ></el-input>
      </div>
      <div class="dialong-item">
        <div class="item-left">收货人姓名</div>
        <el-input
          v-model="ruleForm.name"
          placeholder="不能为昵称、X先生、X小姐等，请使用真实姓名"
          rows="4"
        ></el-input>
      </div>
      <div class="dialong-item">
        <div class="item-left">手机号码</div>
        <el-input
          v-model="ruleForm.phone"
          placeholder="请填写"
          rows="4"
        ></el-input>
      </div>
      <div class="dialong-item">
        <div class="item-left">默认地址</div>
        <el-radio v-model="ruleForm.radio" label="1">是</el-radio>
        <el-radio v-model="ruleForm.radio" label="2">否</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
    <we-chat-payment
      :data="pay"
      :dialogVisible="dialogVisible2"
      v-if="dialogVisible2"
      @close="getClose()"
    ></we-chat-payment>
    <Foter></Foter>
  </div>
</template>
<script>
import Topbar from '../../PCshop/components/topbar.vue'
import Foter from '../../PCshop/components/shopfooter.vue'
import WeChatPayment from "@/components/WeChatPayment.vue"; //微信支付组件
import { shopSettlement, queryUserAddress, addShopOrder, editUserAddress, queryHelpCenter } from "@/api/shop";
import { regionData, CodeToText } from "element-china-area-data";
import { Toast } from "vant";
export default {
  components: {
    Topbar, Foter, WeChatPayment
  },
  data () {
    return {
      // 自提地址
      zitiaddress: '',
      ziti: false,
      beizhu: '',
      pay: {},
      dialogVisible2: false,
      adrs_Id: "", //地址修改id
      selectedOptions: [],
      ruleForm: {
        adressCode: [],
        name: "",
        adress: "",
        phone: "",
        radio: "2"
      },
      options: regionData,
      dialogVisible: false,
      act: 1,
      value: 1,
      remark: "",
      show: false,
      showPay: false,
      showCoupon: false,
      address: {},
      goodsData: [],
      settlement: {},
      pickUpMethod: 0,
      postageMoney: 0,
      pickUpMethodList: ["快递", "自提"],
      payMethodList: ["普利点", "支付宝", "微信"],
      showPickUp: false,
      payMoney: 0,
      payMethod: 0,
      discountMoney: 0,
      jifen: 0,
      orderInfo: {},
      radio: false,
      typelist: [
        {
          ischeck: true,
          img: require('../../../assets/images/pld2.png')
        },
        {
          ischeck: false,
          img: require('../../../assets/images/zfb.png')
        },
        {
          ischeck: false,
          img: require('../../../assets/images/wx.png')
        },
      ],

      addresslist: [
        {
          name: '王大锤',
          ischeck: true,
          street: '重庆市 重庆市 渝中区 某某某街道',
          village: '江南大道83号某某小区',
          phone: '136****6980',
          src: require('../../../assets/images/address-icon.png')
        },
        {
          name: '杨嘉聪',
          ischeck: false,
          street: '重庆市 重庆市 渝中区 某某某街道',
          village: '江南大道83号某某小区',
          phone: '136****6980',
          src: require('../../../assets/images/address-icon.png')
        },
      ],
      way: [
        {
          text: '快递',
          ischeck: true
        },
        {
          text: '自提',
          ischeck: false
        },
      ],
      detailtoplist: [
        {
          text: '商品信息',
        },
        {
          text: '单价(元)',
        },
        {
          text: '数量',
        },
        {
          text: '金额',
        }
      ],
      godslist: [
        {
          price: 160,
          num: 2,
          src: require('../../../assets/images/shopimg.png'),
          font: '帝品狐晶瓷画玄关装饰画轻奢现代简约北欧风抽象 几何艺术入户过道走廊墙壁挂画竖版有框画可定制',
          number: '70679353319',
          off: '￥10'
        },
        {
          price: 170,
          num: 4,
          src: require('../../../assets/images/shopimg.png'),
          font: ' 帝品狐晶瓷画玄关装饰画轻奢现代简约北欧风抽象 几何艺术入户过道走廊墙壁挂画竖版有框画可定制',
          number: '70679353319',
          off: '￥10'
        },
      ],
      addlist: [
        {
          left: '商品总额：',
          right: '￥988'
        },
        {
          left: '运费：',
          right: '￥10'
        },
        {
          left: '优惠券：',
          right: '￥0'
        },
        {
          left: '实付款：',
          right: '￥978',
          important: true,
        },
      ]

    }
  },
  created () {
    this.goodsData = JSON.parse(this.$route.query.goodsData);
    if (undefined != this.$route.query.addressData) {
      this.address = JSON.parse(this.$route.query.addressData);
    }
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.getQueryUserAddress();
      this.postShopSettlement();
      this.getqueryHelpCenter()
    } else {
      this.userInfo.Id = 0;
    }
  },
  mounted () {
  },
  methods: {
    //省市区选择
    handleChangeAdrs (value) {
      console.log(value)
      this.ruleForm.adressCode = value;
    },
    getClose () {
      this.dialogVisible2 = false;
    },
    // 保存地址
    async submitForm () {
      let telStr = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
      if (this.ruleForm.adressCode == '') {
        this.$message({
          message: "请选择省市区",
          type: "warning"
        });
        return
      }
      if (this.ruleForm.adress == '') {
        this.$message({
          message: "请选择详细地址",
          type: "warning"
        });
        return
      }
      if (this.ruleForm.name == '') {
        this.$message({
          message: "请输入姓名",
          type: "warning"
        });
        return
      }
      if (this.ruleForm.phone == '') {
        this.$message({
          message: "请选择手机号",
          type: "warning"
        });
        return
      }
      if (!(telStr.test(this.ruleForm.phone))) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning"
        });
        return
      }
      let params = {};
      params.Id = this.adrs_Id || 0;
      params.UserId = JSON.parse(localStorage.getItem("userInfo")).Id;
      params.Province = CodeToText[this.ruleForm.adressCode[0]];
      params.City = CodeToText[this.ruleForm.adressCode[1]];
      params.Area = CodeToText[this.ruleForm.adressCode[2]];
      params.ZipCode = "";
      params.ContactName = this.ruleForm.name;
      params.ContactPhone = this.ruleForm.phone;
      params.Address = this.ruleForm.adress;
      params.AreaCode = this.ruleForm.adressCode[2];
      params.IsDefault = this.ruleForm.radio == 1 ? "1" : "0";
      console.log(params, '新增地址参数')
      const res = await editUserAddress(params);
      if (res.success == true) {
        this.$message({
          message: "保存成功",
          type: "success"
        });
        this.address.push(params);
        this.dialogVisible = false
        this.getQueryUserAddress();
      } else {
        this.$message.error("保存失败");
      }
    },

    tianjia () {
      this.dialogVisible = true
    },
    guanli () {
      this.$router.push({
        path: "/PCshop/my/shopmy",
        query: { index: 3 },
      });
    },
    // 结算数据
    async postShopSettlement () {
      let parm = {};
      parm.UserId = this.userInfo.Id;
      parm.SettlementDetail = this.goodsData;
      const res = await shopSettlement(parm);
      this.settlement = res.response;
      console.log(this.settlement, 999)
      this.postageMoney = this.settlement.Postage; //所有的运费
      this.payMoney = this.settlement.PayMoney; //要给的所有钱
      this.discountMoney = this.settlement.DiscountMoney; //优惠价的价格
      this.addmoney = this.settlement.TotalMoney //不含商品运费的总价
      this.jifen = this.settlement.TotalPoints
      console.log(this.addlist[0].right)
      this.addlist[0].right = this.jifen == '' ? '￥' + String(this.addmoney) : this.jifen + '积分' + '+' + '￥' + String(this.addmoney)
      this.addlist[1].right = '￥' + this.postageMoney
      this.addlist[2].right = '￥' + this.discountMoney
      this.addlist[3].right = this.jifen == '' ? '￥' + String(this.payMoney) : this.jifen + '积分' + '+' + '￥' + String(this.payMoney)
    },
    // 获取地址数据
    async getQueryUserAddress () {
      //   if(JSON.stringify(this.address)  == '{}')
      //   {
      let parm = {};
      parm.pageIndex = 1;
      parm.pageSize = 10;
      parm.UserId = JSON.parse(localStorage.getItem("userInfo")).Id;
      const res = await queryUserAddress(parm);
      this.address = res.response.data.length > 0 ? res.response.data : {};
      console.log(this.address, 123456)
      this.address.map((item, index) => {
        if (item.IsDefault == 1) {
          this.act = index
        }
      })
    },
    change (idx) {
      this.act = idx
    },
    async changeaddress (idx) {
      let params = this.address[idx];
      params.IsDefault = 1;
      const res = await editUserAddress(params);
      this.getQueryUserAddress();
    },
    change2 (idx, arr) {
      arr = arr.map((item) => {
        item.ischeck = false;
        return item;
      });
      this.$set(arr[idx], "ischeck", "true");
      if (idx == 1) {
        //自提
        // console.log(this.zitiaddress)
        this.pickUpMethod = 1;
        this.postageMoney = 0;
        this.addlist[1].right = 0;
        this.addlist[3].right = this.addmoney - this.discountMoney + this.postageMoney
        this.ziti = true
      } else {
        this.ziti = false
        this.pickUpMethod = 0;
        this.postageMoney = this.settlement.Postage;
        this.addlist[1].right = this.settlement.Postage;
        this.addlist[3].right = this.addmoney - this.discountMoney + this.postageMoney
      }
    },
    change3 (idx, arr) {
      arr = arr.map((item) => {
        item.ischeck = false;
        return item;
      });
      this.$set(arr[idx], "ischeck", "true");
      this.payMethod = idx
    },
    handleCommand (command) {
      this.addlist[2].right = command
    },

    //   togoodsdetail(){
    //        this.$router.push({ name: 'PCshop/my/pay'});
    //   }
    //提交订单
    payshop () {
      // console.log(this.payMethod);
      this.postAddShopOrder()
    },
    // 请求自提地址
    async getqueryHelpCenter () {
      const res = await queryHelpCenter()
      // console.log(res, 887897987)
      this.zitiaddress = res.response.Address

    },
    // 购买
    async postAddShopOrder () {
      let arr = []
      this.address.forEach(item => {
        // console.log(item,555)
        if (item.IsDefault == 1) {
          arr.push(item)
        }
      })
      console.log(arr, 123465789)
      let parm = {};
      parm.UserId = this.userInfo.Id;
      parm.UserName = this.address.ContactName;
      parm.SettlementDetail = this.goodsData;
      parm.Remark = this.beizhu;
      parm.PickUpMethod = this.pickUpMethod;
      parm.PayMethod = this.payMethod;
      parm.OrderSource = "PC";
      parm.ContactName = arr[0].ContactName;
      parm.ContactPhone = arr[0].ContactPhone;
      parm.Address = this.ziti ? this.zitiaddress : arr[0].Province + arr[0].City + arr[0].Area + arr[0].Address;
      if (this.settlement.CouponList.length > 0) {
        parm.CouponId = this.settlement.CouponList[0].Id
      }
      const res = await addShopOrder(parm);
      if (res.success == true) {
        this.orderInfo = res.response;
        console.log(this.orderInfo);
        if (this.payMethod == 1) //支付宝
        {
          const div = document.createElement("div");
          div.id = "alipay";
          div.innerHTML = res.response.PayData;
          document.body.appendChild(div);
          document.querySelector("#alipay").children[0].submit(); //执行后会唤起支付宝
          setTimeout(() => {
            this.$router.push({
              path: "/PCshop/goods/goodssucc",
              query: { orderId: res.response.Id },
            });
          }, 1000)

        }
        if (this.payMethod == 2) //微信
        {
          setTimeout(() => {
            this.dialogVisible2 = true;
            this.pay.code = res.response.PayData;
            this.pay.TotalMoney = this.payMoney;
            this.pay.order = "商城订单";

          }, 500)
        }
        else if (this.payMethod == 0) //普利点
        {
          Toast.success({
            message: res.msg,
          })
          this.$router.push({
            path: "/PCshop/goods/goodssucc",
            query: { orderId: res.response.Id },
          });
        }
      }
      else {
        Toast.fail({
          message: res.msg,
        })
      }
    },
    //支付宝购买
    zhifubao () {
      this.$confirm('是否继续支付?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.continueToPay();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消支付'
        });
      });
    }
  }
}
</script>
<style lang='scss' scoped>
.check-content {
  width: 1100px;
  background: #ffffff;
  padding-top: 26px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px auto 30px;
  padding-bottom: 30px;
  .check-top {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 21px;
    font-size: 16px;
    font-size: #333333;
    font-weight: bold;
  }
  .address {
    flex-wrap: wrap;
    position: relative;
    margin-left: -30px;
    display: flex;
    .address-item {
      cursor: pointer;
      margin-top: 20px;
      margin-left: 30px;
      width: 309px;
      height: 159px;
      background: #fff1ec;
      border: 2px solid #f95e2e;
      padding-left: 17px;
      background-image: url("../../../assets/images/address-icon.png");
      background-repeat: no-repeat;
      background-position: 100% 100%;
      .address-item-top {
        display: flex;
        width: 100%;
        height: 52px;
        border-bottom: 1px solid #eeeeee;
        padding-right: 16px;
        justify-content: space-between;
        .name {
          font-size: 14px;
          color: #333333;
          line-height: 52px;
        }
        .default {
          font-size: 14px;
          color: #999999;
          line-height: 52px;
        }
      }
      .address-item-content {
        margin-top: 13px;
        line-height: 25px;
      }
    }
    // 不是默认地址
    .address-item2 {
      cursor: pointer;
      margin-top: 20px;
      margin-left: 30px;
      width: 309px;
      height: 159px;
      background: #ffffff;
      border: 2px solid #eeeeee;
      padding-left: 17px;
      .address-item-top {
        display: flex;
        width: 100%;
        height: 52px;
        border-bottom: 1px solid #eeeeee;
        padding-right: 16px;
        justify-content: space-between;
        .name {
          font-size: 14px;
          color: #333333;
          line-height: 52px;
        }
        .default {
          font-size: 14px;
          color: #999999;
          line-height: 52px;
        }
      }
      .address-item-content {
        margin-top: 13px;
        line-height: 25px;
      }
    }
    .address-icon {
      width: 33px;
      height: 34px;
      position: absolute;
      top: 78%;
      left: 26%;
    }
  }

  .check-top2 {
    padding-bottom: 21px;
    font-size: 16px;
    font-size: #333333;
    font-weight: bold;
    margin-top: 30px;
  }
}
.bigadd {
  display: flex;
}
.add {
  cursor: pointer;
  width: 112px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #707070;
  line-height: 28px;
  text-align: center;
  margin-top: 20px;
}
.way {
  cursor: pointer;
  display: flex;
  width: 180px;
  justify-content: space-between;
  .package {
    width: 80px;
    height: 32px;
    background: #fff1ec;
    border: 1px solid #f95e2e;
    text-align: center;
    line-height: 32px;
    color: #f95e2e;
  }
  .Self {
    width: 80px;
    height: 32px;
    border: 1px solid #eeeeee;
    text-align: center;
    background: #ffffff;
    line-height: 32px;
    color: #333333;
  }
}
.shop-detail {
  border: 1px solid #efefef;
  .detail-top {
    width: 1060px;
    height: 50px;
    background: #f9f9f9;
    border: 1px solid #efefef;
    display: flex;
    .top-item {
      line-height: 50px;
      font-size: 14px;
    }
    .top-item:nth-child(1) {
      margin-left: 193px;
    }
    .top-item:nth-child(2) {
      margin-left: 298px;
    }
    .top-item:nth-child(3) {
      margin-left: 166px;
    }
    .top-item:nth-child(4) {
      margin-left: 158px;
    }
  }

  .goods-item {
    display: flex;
    border-bottom: 1px solid #e9e9e9;
    padding-left: 21px;
    padding-top: 21px;
    padding-bottom: 20px;
    .content-left-img {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content-left-font {
      max-width: 308px;
      margin-left: 14px;
      .font1 {
        width: 308px;
        height: 38px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .font2 {
        font-size: 12px;
        color: #999999;
        margin-top: 7px;
      }
    }
    .content-jifen {
      margin-left: 94px;
    }
    .content-price {
    }
    .content-price2 {
      min-width: 72px;
      text-align: center;
      margin-left: 130px;
    }
    .content-num {
      min-width: 24px;
      margin-left: 163px;
      text-align: center;
    }
    .off {
      min-width: 72px;
      text-align: center;
      margin-left: 136px;
    }
  }
  .liuyanbox {
    display: flex;
    width: 100%;
    height: 50px;
    border: 1px solid #e9e9e9;
    padding: 5px 20px;
    .liuyanfont {
      line-height: 38px;
    }
    input {
      width: 500px;
      height: 36px;
      border: 1px solid #e9e9e9;
      padding-left: 15px;
    }
    .beizhunum {
      margin-left: 10px;
      line-height: 38px;
    }
  }
}

.goods-add {
  .pay {
    margin-top: -30px;
    width: 40%;
  }
  width: 100%;
  height: 160px;
  margin: 20px auto 0px;
  padding-top: 10px;
  position: relative;
  display: flex;
  .youhui {
    position: absolute;
    left: 87%;
    top: 51%;
  }
  .goods-content {
    margin-left: auto;
    width: 21%;
    .goods-content-item {
      margin-top: 10px;
      margin-right: 0px;
      display: flex;
      justify-content: space-between;
      .goods-left {
        font-weight: bold;
      }
    }
    .goods-content-item2 {
      margin-top: 10px;
      margin-right: 0px;
      display: flex;
      justify-content: space-between;
      .goods-left {
        font-weight: bold;
        color: #fa6639;
      }
      .goods-right {
        font-size: 14px;
        font-weight: bold;
        color: #fa6639;
      }
    }
  }
}
.queren {
  cursor: pointer;
  width: 130px;
  height: 40px;
  background: #fa6639;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  font-size: 16px;
  margin-left: auto;
}

//  弹出框
.fankui {
  border: 1px solid red;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 660px;
  background: #ffffff;
  z-index: 999;
  margin-left: -330px;
  margin-top: -219px;
  .fankui-title {
    width: 100%;
    height: 73px;
    font-size: 16px;
    color: #9c9c9c;
    font-weight: bold;
    border-bottom: 1px solid #d9d0cd;
    line-height: 73px;
    padding-left: 29px;
  }
  .addres {
    .text {
      display: flex;
      margin-right: auto;
      .text-icon {
        color: #f52727;
      }
      .text-name {
        margin-left: 5px;
      }
    }
  }
  .name {
    input {
      width: 460px;
      height: 36px;
      border: 1px solid #dddddd;
      padding-left: 15px;
    }
    .text {
      display: flex;
      .text-icon {
        color: #f52727;
      }
      .text-name {
        margin-left: 5px;
      }
    }
  }
  .phone {
    .text {
      display: flex;
      .text-icon {
        color: #f52727;
      }
      .text-name {
        margin-left: 5px;
      }
    }
    input {
      width: 460px;
      height: 36px;
      border: 1px solid #dddddd;
      padding-left: 15px;
    }
  }
  .content {
    .text {
      display: flex;
      .text-icon {
        color: #f52727;
      }
      .text-name {
        margin-left: 5px;
      }
    }
    input {
      width: 460px;
      height: 86px;
      border: 1px solid #dddddd;
      padding-left: 15px;
    }
  }
  .content,
  .phone,
  .name,
  .addres {
    display: flex;
    justify-content: space-between;
    padding-right: 43px;
    margin-top: 34px;
  }
  .text {
    margin-right: 30px;
    margin-left: auto;
    margin-top: 5px;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #c1c1c1;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c1c1c1;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c1c1c1;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #c1c1c1;
  }
  .addbottom {
    display: flex;
    margin-left: 157px;
    margin-bottom: 30px;
    margin-top: 30px;
    .quxiao {
      width: 130px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #dddddd;
      text-align: center;
      line-height: 40px;
    }
    .baocun {
      margin-left: 30px;
      width: 130px;
      height: 40px;
      background: #f95e2e;
      text-align: center;
      line-height: 40px;
      margin-left: 30px;
      color: #ffffff;
    }
  }
}

.type-select {
  .type-item {
    cursor: pointer;
    display: flex;
    margin-top: 40px;
    .type-left {
      width: 18px;
      height: 18px;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 50%;
    }
    .type-left2 {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-image: url("../../../assets/images/pay-select.png");
      background-repeat: no-repeat;
    }
    .type-right {
      margin-left: 52px;
      margin-top: -5px;
    }
  }
}
.dialong-item {
  margin-top: 15px;
}
</style>